* {
    box-sizing: border-box;
}

:root {
    --theme-color: #ffa033;
    --page-gap-horizontal: 100px;
    --page-gap-vertical: 100px;
    --section-gap: 50px;
}

html,
body {
    margin: 0;
    padding: 0;
    min-height: 100%;
}

h1,
h2,
p {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: all linear 0.2s;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

.section {
    width: 100%;
    padding: var(--page-gap-vertical) var(--page-gap-horizontal);

    &:nth-child(2n) {
        background-color: rgba(255, 160, 51, 0.05);
    }

    &:nth-child(4) {
        background-color: #fafafa;
    }

    h2 {
        margin-bottom: var(--section-gap);
        font-weight: normal;
        font-size: 50px;
    }
}

#leadspace {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        'a c'
        'b c';
    grid-gap: var(--section-gap);

    h1 {
        font-weight: normal;
        font-size: 70px;
    }

    p {
        margin-top: 20px;
        font-size: 20px;
        line-height: 1.5;

        strong {
            text-decoration: underline;
        }
    }

    nav {
        margin-top: 20px;
        font-size: 24px;
        line-height: 2;

        a {
            display: block;

            &:hover {
                color: var(--theme-color);
            }
        }
    }

    #logo {
        grid-area: a;
        border-radius: 100%;
        width: 64px;
        height: 64px;
        background: url('../images/logo.webp') no-repeat center center;
        background-size: cover;
    }

    #introduction {
        grid-area: b;
    }

    #photo {
        grid-area: c;
        background: #d6e9e5 url('../images/photo.webp') no-repeat center center;
        background-size: cover;
        width: 100%;
        height: calc(100vh - var(--page-gap-vertical) * 2);
    }
}

#projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--section-gap);

    .project {
        display: block;
        background: no-repeat center center;
        background-size: cover;
        padding-top: 56%;

        &:hover {
            opacity: 0.8;
        }
    }
}

#magzines {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--section-gap);

    .magzine {
        display: block;
        background: no-repeat center center;
        background-size: cover;
        padding-top: 142%;

        &:hover {
            opacity: 0.8;
        }
    }
}

#contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--section-gap);

    .contact {
        padding: 64px;
        background-color: #fff;

        &-icon {
            margin: 0 auto;
            display: block;
            width: 36px;
            height: 36px;
            filter: invert(0);
            transition: filter linear 0.2s;
        }

        &:hover {
            background-color: var(--theme-color);

            .contact-icon {
                filter: invert(1);
            }
        }
    }
}

#footer {
    text-align: center;
    font-size: 20px;
    color: #666;
}
