* {
  box-sizing: border-box;
}

:root {
  --theme-color: #ffa033;
  --page-gap-horizontal: 100px;
  --page-gap-vertical: 100px;
  --section-gap: 50px;
}

html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s linear;
}

body {
  font-family: Source Sans Pro, sans-serif;
}

.section {
  width: 100%;
  padding: var(--page-gap-vertical) var(--page-gap-horizontal);
}

.section:nth-child(2n) {
  background-color: #ffa0330d;
}

.section:nth-child(4) {
  background-color: #fafafa;
}

.section h2 {
  margin-bottom: var(--section-gap);
  font-size: 50px;
  font-weight: normal;
}

#leadspace {
  grid-gap: var(--section-gap);
  grid-template-rows: 1fr auto;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a c"
                       "b c";
  display: grid;
}

#leadspace h1 {
  font-size: 70px;
  font-weight: normal;
}

#leadspace p {
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.5;
}

#leadspace p strong {
  text-decoration: underline;
}

#leadspace nav {
  margin-top: 20px;
  font-size: 24px;
  line-height: 2;
}

#leadspace nav a {
  display: block;
}

#leadspace nav a:hover {
  color: var(--theme-color);
}

#leadspace #logo {
  width: 64px;
  height: 64px;
  background: url("logo.10c5370e.webp") center / cover no-repeat;
  border-radius: 100%;
  grid-area: a;
}

#leadspace #introduction {
  grid-area: b;
}

#leadspace #photo {
  width: 100%;
  height: calc(100vh - var(--page-gap-vertical) * 2);
  background: #d6e9e5 url("photo.c3553429.webp") center / cover no-repeat;
  grid-area: c;
}

#projects {
  grid-gap: var(--section-gap);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

#projects .project {
  background: center / cover no-repeat;
  padding-top: 56%;
  display: block;
}

#projects .project:hover {
  opacity: .8;
}

#magzines {
  grid-gap: var(--section-gap);
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

#magzines .magzine {
  background: center / cover no-repeat;
  padding-top: 142%;
  display: block;
}

#magzines .magzine:hover {
  opacity: .8;
}

#contacts {
  grid-gap: var(--section-gap);
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

#contacts .contact {
  background-color: #fff;
  padding: 64px;
}

#contacts .contact-icon {
  width: 36px;
  height: 36px;
  filter: invert(0);
  margin: 0 auto;
  transition: filter .2s linear;
  display: block;
}

#contacts .contact:hover {
  background-color: var(--theme-color);
}

#contacts .contact:hover .contact-icon {
  filter: invert();
}

#footer {
  text-align: center;
  color: #666;
  font-size: 20px;
}

/*# sourceMappingURL=index.01a4c5b7.css.map */
